import request from '@/util/request'

export function getApproveList(params) {
  return request({
    url: '/Approve.asmx/GetApproveList',
    method: 'GET',
    params,
  })
}

export function getApproveInfo(params) {
  return request({
    url: '/Approve.asmx/GetApproveInfo',
    method: 'GET',
    params,
  })
}
